<template>
  <BaseButton
    md-icon="add"
    md-icon-position="left"
    text-color="blue darken-3"
    color="blue darken-3"
    small
    round
    outline
    :icon="icon"
    class="first-addon-btn ui"
    v-on="$listeners"
    ><slot v-if="!icon">Addon</slot></BaseButton
  >
</template>

<script>
export default {
  name: 'ButtonNewAddon',
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.first-addon-btn.v-btn {
  z-index: 920;
  margin-right: 0;
  margin-left: 0;
  font-size: 11px;
  &.v-btn--small {
    height: 24px;
    padding: 0 6px;
  }
  .v-icon--left {
    margin-right: 5px;
  }
}
</style>
